import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = environment.api.url;

  constructor(private http: HttpClient) {}

  addRecords(record: any) {
    return this.http.post(`${environment.api.url}/v1/transactions`, record);
  }

  editRecords(record: any, id: number) {
    return this.http.patch(`${environment.api.url}/v1/transactions/${id}`, record);
  }

  getRecords(tabPanel: any, pageSize: any, pageNumber: any, sortName: any, sort: any, filter: any) {
    return this.http.get(
      `${environment.api.url}/v1/${tabPanel}/?pageSize=${pageSize}&pageNumber=${pageNumber}&sortCol=${sortName}&sortType=${sort}&filter=${filter}`
    );
  }
}
