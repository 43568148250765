import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { InterceptorModule } from './core/interceptors/interceptor.module';
import { AuthGuardModule } from './core/guards/auth-guard.module';

import { ServicesModule } from './services/services.module';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AppLayoutComponent } from './shared/_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './shared/_layout/site-layout/site-layout.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {OverlayPanelModule} from 'primeng/overlaypanel';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, AppLayoutComponent, SiteLayoutComponent, LoaderComponent],
  imports: [BrowserModule, AppRoutingModule, AuthGuardModule, InterceptorModule, ServicesModule, BrowserAnimationsModule, ToastModule,OverlayPanelModule],
  providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' }, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
