import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private messageService: MessageService) {}

  showToaster(
    config: {
      severity?: 'success' | 'info' | 'warn' | 'error';
      summary?: string;
      detail: string;
    } = { detail: 'Successful' }
  ) {
    this.messageService.add({
      severity: config.severity || 'success',
      summary: config.summary || 'Success',
      detail: config.detail,
    });
  }
}
