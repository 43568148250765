/**
 * Http Service wrapper for every API calls
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor, OnDestroy {
  private token = '';
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userInfoService: UserInfoService, private router: Router) {
    if (!this.token) {
      this.token = sessionStorage.getItem('token') || '';
    }
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clone = httpRequest.clone();
    clone = httpRequest.clone({
      setHeaders: { 'api-token': this.token, Authorization: 'Bearer ' + this.token },
    });

    return next.handle(clone).pipe(
      tap((data) => {
        // Future Implementation : Need to do with Response Body
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401 || error.message === 'Unauthorised Access') {
          //Logout functionality need to implement
          this.userInfoService.logout();
          this.router.navigateByUrl('/login');
        }
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
        } else {
          console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
      })
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
