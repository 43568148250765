import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from '../interceptors/interceptor';
import { CustomErrorHandler } from '../interceptors/custom-error-handler';

@NgModule({
  // eslint-disable-next-line max-len
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
  ],
})
export class InterceptorModule {}
