import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderlog: LoaderLog[] = [];
  private loader = new BehaviorSubject<LoaderDataModule>({ show: false });

  constructor() {}

  get loaderSub(): Observable<LoaderDataModule> {
    return this.loader;
  }

  showLoader(message?: string) {
    message = message || 'Please Wait...';
    this.loaderlog.push({ message });
    if (this.loaderlog.length === 1) {
      this.emitLoader();
    }
  }
  hideLoader() {
    this.loaderlog.pop();
    this.emitLoader();
  }
  private emitLoader() {
    const concernedLoader = this.loaderlog[this.loaderlog.length - 1];
    this.loader.next({
      message: concernedLoader && concernedLoader.message,
      show: !!this.loaderlog.length,
    });
  }
}

export interface LoaderLog {
  message?: string;
}

export interface LoaderDataModule {
  message?: string;
  show: boolean;
}
