import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(error: any) {
    super.handleError(error);
    this.handleHttpError(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.log('Chunk Issue');
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  }

  handleHttpError(response: any) {
    // TODO: Error logging at server end using API endpoint
  }
}
