<div class="toolbar" role="banner">
  <img width="40" alt="Incred Logo" src="../../../../assets/images/logo.png" />
  <div class="spacer"></div>
  <div class="user">
    <span>Welcome {{ userName }} </span>
    <img width="40px" alt="Incred Logo" src="../../../../assets/images/user.svg" class="cursor-pointer" (click)="op.toggle($event)" />

    <p-overlayPanel #op>
      <ng-template pTemplate>
        <div>Name: {{ userName }}</div>
        <div style="margin-top: 5%; display: flex; justify-content: center">Role: {{ userRole }}</div>
        <div style="display: flex; justify-content: center">
          <button
            (click)="logout()"
            style="border: none; background-color: #f60; color: #fff; margin-top: 5%; border-radius: 10px; padding: 7px"
          >
            Sign out
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>
</div>
