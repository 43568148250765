import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  userInfo: any;
  token: any;
  userRole: any;
  userPermission: any;

  constructor() {}

  setUserInfo(user: any) {
    this.userInfo = user;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
  }

  getUserInfo() {
    this.userInfo = this.userInfo || JSON.parse(sessionStorage.getItem('userInfo') || '');
    let user = this.userInfo || {};
    return user;
  }

  getUserRole() {
    this.userRole = this.userRole || sessionStorage.getItem('userRole');
    let userRole = this.userRole || '';
    return userRole;
  }

  setUserRole(role: any) {
    this.userRole = role;
    sessionStorage.setItem('userRole', role);
  }

  getUserPermission() {
    this.userPermission = this.userPermission || JSON.parse(sessionStorage.getItem('userPermission') || '');
    let permission = this.userPermission || {};
    return permission;
  }

  setUserPermission(permission: any) {
    this.userPermission = permission;
    sessionStorage.setItem('userPermission', JSON.stringify(permission));
  }

  setToken(token: any) {
    this.token = token;
    sessionStorage.setItem('token', token);
  }

  getToken() {
    this.userInfo = this.token || sessionStorage.getItem('token');
    let token = this.token || '';
    return token;
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
  }
}
