import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userName: any;
  userRole: any = this.userInfoService.getUserRole();
  constructor(private userInfoService: UserInfoService, private router: Router) {}

  ngOnInit(): void {
    if (this.userInfoService.getUserInfo()) {
      this.userName = this.userInfoService.getUserInfo().name;
    }
  }

  logout() {
    this.userInfoService.logout();
    this.router.navigateByUrl('/login?logout=true');
  }
}
