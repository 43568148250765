import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = sessionStorage.getItem('token');
    if (token) {
      return true;
    } else {
      sessionStorage.setItem('redirectTo', state.url);
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
