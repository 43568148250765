import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoService } from './user-info/user-info.service';
import { ToasterService } from './toaster/toaster.service';
import { ApiService } from './api/api.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [UserInfoService, ToasterService, ApiService],
})
export class ServicesModule {}
