import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AppLayoutComponent } from './shared/_layout/app-layout/app-layout.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'create-record',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/create-records/create-records.module').then((m) => m.CreateRecordsModule),
  },
  {
    path: 'login',
    component: AppLayoutComponent,
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
